import api from "./Api/Academico"

/**
 * @param {String} matricula
 * @param {String} foto
 * @returns {Promise<AxiosResponse<any>>}
 */
export function salvar (proprietario, matricula, foto) {
  return new Promise((resolve, reject) =>
    api
      .post("/foto/", { proprietario, matricula, foto })
      .then(response => resolve(response.data))
      .catch(erro => reject(erro))
  )
}

/**
 * @param {string} proprietario
 * @param {String} matricula
 * @returns {Promise<AxiosResponse<any>>}
 */
export function buscar (proprietario, matricula) {
  return new Promise((resolve, reject) =>
    api
      .get(`/foto/?matricula=${matricula}&proprietario=${proprietario}`)
      .then(response => resolve(response.data))
      .catch(erro => reject(erro))
  )
}

/**
 * @param {String} proprietario
 * @param {String} matricula
 * @param {String} segundaVia
 * @returns {Promise<AxiosResponse<any>>}
 */
export function salvarSolicitacao (proprietario, matricula, segundaVia) {
  return new Promise((resolve, reject) =>
    api
      .post("/solicitacao/", { proprietario, matricula, segundaVia })
      .then(response => resolve(response.data))
      .catch(erro => reject(erro))
  )
}
