const arquivo = {
  /**
     * Converte um arquivo do tipo File em Base64
     *
     * @param {File} arquivo
     * @returns {Promise}
     */
  async converterParaBase64 (arquivo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(arquivo)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
}

export default arquivo
