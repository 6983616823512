import axios from "axios"

const academico = axios.create({
  baseURL: process.env.NODE_ENV !== "production" ? "https://localhost:44383/api/" : "https://www.ufn.edu.br/api/api/",
  headers: {
    Accept: "application/json",
    Content: "application/json",
  },
})

export default academico
