<template>
    <v-app class="mt-2">
        <v-container fluid>
            <v-row
                align="start"
                justify="center">
                <v-col
                    v-if="this.alertaVisivel"
                    cols="12"
                    sm="12"
                    md="7"
                    lg="10">
                    <v-alert
                        :type="this.tipoMensagem">
                        {{ this.mensagem }}
                    </v-alert>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="7"
                    lg="5">
                    <v-card
                        min-height="580px"
                        class="elevation-8">
                        <v-card-actions class="justify-center">
                            <v-text-field
                                v-model="matricula"
                                label="Matricula"
                                class="mt-3"
                                outlined
                                filled/>
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn
                                :loading="isLoadingSearch"
                                color="primary"
                                @click="buscarPessoa">
                                <v-icon>mdi-magnify</v-icon> Buscar
                            </v-btn>
                        </v-card-actions>
                        <v-card-text v-if="this.nomePessoa != ''">
                            <v-divider/>
                            <h2 class="mt-5">{{ this.nomePessoa }}</h2>
                            <span>{{ this.relacionamento }}</span>
                            <v-img
                                :src="`data:image/png;base64,${ this.fotoPessoa }`"
                                width="25%"
                                class="my-5" />
                        </v-card-text>
                        <v-divider v-if="flagBuscar"/>
                        <v-card-actions v-if="flagBuscar">
                            <v-checkbox
                                v-model="segundaVia"
                                label="Segunda Via"
                                class="ml-2"
                            />
                            <v-spacer/>
                            <v-btn
                                :loading="isLoadingSolicitacao"
                                color="primary"
                                class="mr-2"
                                @click="salvarSolicitacao">
                                Solicitar Carteira
                            </v-btn>
                        </v-card-actions>
                        <v-divider v-if="fotoSalva"/>
                        <v-card-text v-if="this.nomePessoa != ''">
                            <label>Ultimas solicitações</label>
                            <v-data-table
                                :header-props="{'sort-by-text': 'Ordenar por'}"
                                :footer-props="{'items-per-page-text': 'Solicitações por pagina:'}"
                                :headers="cabecalhoTabela"
                                :items="listaImpressaoCarteiras"
                                no-data-text="Nenhum item encontrado"
                            >
                                <template #[`item.IMPRESSO`]="{ item }">
                                    <i
                                        v-if="item.IMPRESSO == 'S'"
                                        class='mdi mdi-check'/>
                                    <i
                                        v-else
                                        class='mdi mdi-close'/>
                                </template>
                                <template #[`item.SEG_VIA`]="{ item }">
                                    <i
                                        v-if="item.SEG_VIA == 'S'"
                                        class='mdi mdi-check'/>
                                    <i
                                        v-else
                                        class='mdi mdi-close'/>
                                </template>
                                <template #[`item.DT_IMPRESSAO`]="{ item }">
                                    <span v-if="item.DT_IMPRESSAO != null"> {{ item.DT_IMPRESSAO.split('T')[0].split('-')[2] }}/{{ item.DT_IMPRESSAO.split('T')[0].split('-')[1] }}/{{ item.DT_IMPRESSAO.split('T')[0].split('-')[0] }} </span>
                                </template>
                                <template #[`footer.page-text`]="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="7"
                    lg="5">
                    <v-card
                        min-height="580px"
                        class="elevation-8">
                        <v-card-actions class="justify-center">
                            <v-file-input
                                v-model="imagemUpload"
                                counter
                                :rules="regrasFileInput"
                                show-size
                                small-chips
                                label="enviar imagem"
                                prepend-icon="mdi-attachment"
                                type="file"
                                accept="image/*"
                                truncate-length="15"/>
                        </v-card-actions>
                        <v-card-actions class="justify-center pt-5">
                            <v-btn
                                color="primary"
                                :loading="isLoading"
                                @click="toggleCamera">
                                <span v-if="!isCameraOpen">Abrir Câmera</span>
                                <span v-else>Fechar Câmera</span>
                            </v-btn>
                        </v-card-actions>
                        <v-card-text
                            v-if="isCameraOpen"
                            v-show="!isLoading"
                            align="center"
                            justify="center">
                            <div
                                class="camera-shutter"
                                :class="{'flash' : isShotPhoto}"/>

                            <div
                                v-if="!isPhotoTaken"
                                class="opacidade-camera">
                                <div class="opacidade-camera-esquerda" />
                                <div class="opacidade-camera-direita" />
                            </div>

                            <video
                                v-show="!isPhotoTaken"
                                ref="camera"
                                :width="480"
                                :height="360"
                                autoplay/>

                            <canvas
                                v-show="isPhotoTaken"
                                id="photoTaken"
                                ref="canvas"
                                :width="270"
                                :height="360"/>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn
                                v-if="isCameraOpen && !isLoading"
                                plain
                                @click="takePhoto">
                                <v-icon
                                    v-if="!isPhotoTaken"
                                    x-large
                                    color="green darken-2">
                                    mdi-camera
                                </v-icon>
                                <v-icon
                                    v-else
                                    x-large
                                    color="red darken-2">
                                    mdi-camera-retake
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="justify-center">
                            <v-btn
                                v-if="(imagemUpload != null || isPhotoTaken && isCameraOpen) && matriculaPessoa != ''"
                                color="primary"
                                :loading="isLoadingSend"
                                @click="salvarFoto">
                                <v-icon>mdi-content-save</v-icon> Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import { salvar, buscar, salvarSolicitacao } from "@/services/FotoService"

import _arquivo from "../utils/arquivo"

export default {
  name: "Home",
  components: {},
  data () {
    return {
      regrasFileInput: [
        (v) => !v || v.size < 2000000 || "O anexo precisa ser menor que 2 MB!",
      ],
      matricula: null,
      proprietario: this.$route.params.proprietario,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      isLoadingSearch: false,
      isLoadingSend: false,
      isLoadingSolicitacao: false,
      fotoSalva: false,
      flagBuscar: false,
      nomePessoa: "",
      matriculaPessoa: "",
      fotoPessoa: "",
      relacionamento: "",
      listaImpressaoCarteiras: [],
      cabecalhoTabela: [
        { text: "Data Impressão", align: "start", value: "DT_IMPRESSAO" },
        { text: "Impresso", value: "IMPRESSO" },
        { text: "Segunda Via", value: "SEG_VIA" },
        { text: "Relacionamento", value: "RELACIONAMENTO" },
        { text: "Código de Barras", value: "COD_BARRA" },
      ],
      mensagem: "",
      alertaVisivel: false,
      tipoMensagem: "",
      segundaVia: false,
      imagemUpload: null,
    }
  },

  created () {
    this.$vuetify.theme.dark = parseInt(this.$route.params.dark) === 1
  },

  methods: {
    async buscarPessoa () {
      this.isLoadingSearch = true
      await buscar(this.proprietario, this.matricula)
        .then(retorno => {
          window.scrollTo(0, 0)

          if (this.matricula !== this.matriculaPessoa) {
            this.fotoSalva = false
          }

          this.nomePessoa = retorno.nomePessoa
          this.matriculaPessoa = this.matricula
          this.fotoPessoa = retorno.fotoPessoa
          this.relacionamento = retorno.relacionamento
          this.listaImpressaoCarteiras = retorno.listaImpressaoCarteiras
          this.flagBuscar = true
        })
        .catch(erro => {
          window.scrollTo(0, 0)
          this.mensagem = JSON.parse(erro.request.response).Message
          this.tipoMensagem = "error"

          this.fotoSalva = false
          this.nomePessoa = ""
          this.matriculaPessoa = ""
          this.fotoPessoa = ""
          this.relacionamento = ""
          this.listaImpressaoCarteiras = []

          this.alertaVisivel = true
          setTimeout(() => {
            this.alertaVisivel = false
          }, 5000)
        })
        .finally(() => { this.isLoadingSearch = false })
    },

    toggleCamera () {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },

    createCameraElement () {
      this.isLoading = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false
          this.$refs.camera.srcObject = stream
        })
        .catch(() => {
          this.isLoading = false
          alert("Ocorreu um erro ao abrir a câmera. Pode estar desconectada ou já sendo usada.")
        })
    },

    stopCameraStream () {
      const tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },

    takePhoto () {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true
        setTimeout(() => {
          this.isShotPhoto = false
        }, 50)
      }

      this.isPhotoTaken = !this.isPhotoTaken

      const context = this.$refs.canvas.getContext("2d")
      context.drawImage(this.$refs.camera,
        140, 0, // Optional. The x, y coordinate where to start clipping
        360, 480, // Optional. The width, height of the clipped image
        0, 0, // The x, y coordinate where to place the image on the canvas
        270, 360) // Optional. The width, height of the image to use (stretch or reduce the image)
    },

    async salvarFoto () {
      this.isLoadingSend = true
      var foto = null
      if (document.getElementById("photoTaken")) {
        foto = document.getElementById("photoTaken").toDataURL("image/png")
      }

      if (this.imagemUpload && foto) {
        window.scrollTo(0, 0)
        this.mensagem = "Insira somente uma imagem ou uma foto."
        this.tipoMensagem = "error"
        this.alertaVisivel = true
        this.isLoadingSend = false
        setTimeout(() => {
          this.alertaVisivel = false
        }, 5000)
      } else {
        await salvar(this.proprietario, this.matriculaPessoa, foto || await _arquivo.converterParaBase64(this.imagemUpload))
          .then(retorno => {
            window.scrollTo(0, 0)
            this.buscarPessoa()
            this.fotoSalva = true
            this.mensagem = retorno
            this.tipoMensagem = "success"
          })
          .catch(erro => {
            window.scrollTo(0, 0)
            this.mensagem = JSON.parse(erro.request.response).Message
            this.tipoMensagem = "error"
          })
          .finally(() => {
            this.isLoadingSend = false
            this.alertaVisivel = true
            setTimeout(() => {
              this.alertaVisivel = false
            }, 5000)
          })
      }
    },

    async salvarSolicitacao () {
      this.isLoadingSolicitacao = true
      await salvarSolicitacao(this.proprietario, this.matriculaPessoa, this.segundaVia ? "S" : "N")
        .then(retorno => {
          window.scrollTo(0, 0)
          this.buscarPessoa()
          this.fotoSalva = false
          this.mensagem = retorno
          this.tipoMensagem = "success"
        })
        .catch(erro => {
          window.scrollTo(0, 0)
          this.mensagem = JSON.parse(erro.request.response).Message
          this.tipoMensagem = "error"
        })
        .finally(() => {
          this.isLoadingSolicitacao = false
          this.alertaVisivel = true
          setTimeout(() => {
            this.alertaVisivel = false
          }, 5000)
        })
    },
  },
}
</script>

<style scoped>

    .opacidade-camera{
        display: inline;
        position: absolute;
        height: 360px;
        width: 480px;
    }
    .opacidade-camera-esquerda{
        opacity: 0.7;
        position: absolute;
        height: 360px;
        width: 105px;
        background-color: #000;
    }
    .opacidade-camera-direita{
        opacity: 0.7;
        position: absolute;
        margin-left: 375px;
        height: 360px;
        width: 105px;
        background-color: #000;
    }

</style>
